import React, {useEffect} from 'react';
import s from './Auth.module.scss';
import BackgroundImage from './../../assets/images/auth/background-image.png';
import Hero from "../Hero/Hero";
import {useNavigate} from "react-router-dom";
import {login} from "../../store/actionCreators";
import {useAppSelector} from "../../store/hooks";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import PasswordReset from "./components/PasswordReset/PasswordReset";

interface IAuth {
    type: 'login' | 'signup' | 'forgotPassword' | 'passwordReset'
}
const Auth = ({type}: IAuth) => {
    let authData = useAppSelector((state) => state.auth);
    let navigate = useNavigate();

    useEffect(() => {
        if (authData.data.isAuth) {
            navigate("/dashboard");
        }
    }, [authData.data.isAuth]);

    return (
        <section className={s.auth}>
            <div className={s.auth__background}>
                <Hero isBackground={true}/>
                {/*<img src={BackgroundImage} alt=""/>*/}
            </div>
            <div className={s.auth__block}>
                {type == "login" && <Login/>}
                {type == "signup" && <Signup/>}
                {type == "forgotPassword" && <ForgotPassword/>}
                {type == "passwordReset" && <PasswordReset/>}
            </div>
        </section>
    );
};

export default Auth;