import React from 'react';
import s from './Companies.module.scss';
import Rmit from '../../assets/images/companies/rmit.png';
import ReedGraduations from '../../assets/images/companies/reedgraduations.png';
import Echo from '../../assets/images/companies/echo.png';
import Rpdata from '../../assets/images/companies/rpdata.png';
import Nsw from '../../assets/images/companies/nsw.png';
import classNames from "classnames";

interface ICompanies {
    isAlt?: boolean
}
const Companies = ({isAlt}: ICompanies) => {
    return (
        <section className={classNames(s.companies, isAlt && s.companies_alt)}>
            <div className={"container"}>
                {isAlt && <h2>Our previous clients</h2>}
                <div className={s.companies__items}>
                    <a href="#" className={s['companies__items-item']}><img src={Rmit} alt="Echo"/></a>
                    <a href="#" className={s['companies__items-item']}><img src={ReedGraduations} alt="Echo"/></a>
                    <a href="#" className={s['companies__items-item']}><img src={Echo} alt="Echo"/></a>
                    <a href="#" className={s['companies__items-item']}><img src={Rpdata} alt="Echo"/></a>
                    <a href="#" className={s['companies__items-item']}><img src={Nsw} alt="Echo"/></a>
                </div>
            </div>
        </section>
    );
};

export default Companies;