import React, {useEffect, useState} from 'react';
import s from './ProfileSettings.module.scss';
import Photo from './../../assets/images/settings/photo.svg';
import { ReactComponent as Edit } from './../../assets/images/icons/edit.svg';
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import {backendAPI, instance} from "../../api/backendAPI";
import PaymentMethods from "./components/PaymentMethods/PaymentMethods";
import Notifications from "./components/Notifications/Notifications";
import Loader from "../UI/Loader/Loader";
import toast from "react-hot-toast";
import {Formik, FormikValues} from "formik";
import * as Yup from "yup";
import ChangePassword from "./components/ChangePassword/ChangePassword";

const ProfileSettings = () => {
    let [isLoading, setIsLoading] = useState(true);
    let [phonePrefixes, setPhonePrefixes] = useState([{prefix: "+61", selected: true}]);
    let [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    });
    let [notificationsValues, setNotificationsValues] = useState<any>([false, false]);

    let fetchData = async () => {
        setIsLoading(true)
        let response = await backendAPI.fetchProfileInfo();
        setInitialValues({
            firstName: response.first_name,
            lastName: response.last_name,
            email: response.email,
            phone: response.phone_number.substring(3)
        });
        setNotificationsValues([response.email_enabled, response.sms_enabled]);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) {
        return <Loader isFull={true}/>
    }

    const ProfileSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(0)
            .max(255)
            .required(),
        lastName: Yup.string()
            .min(0)
            .max(255)
            .required(),
        phone: Yup.string()
            .min(0)
            .max(255)
            .required(),
        email: Yup.string()
            .email()
            .min(0)
            .max(255)
            .required(),
    });

    return (
        <section>
            <div className={s.settings}>
                <div className={s.settings__block}>
                    <div className={s['settings__block-info']}>
                        <h2>Personal Information</h2>
                        <p>Set your name and contact info here. The email address entered here is used for your login access.</p>
                    </div>
                    <div className={s.profile}>
                        <div className={s.profile__photo}>
                            <img src={Photo} alt=""/>
                            <button><Edit/></button>
                        </div>
                        <Formik initialValues={initialValues} validationSchema={ProfileSchema}
                                onSubmit={async (values, {resetForm}) => {
                                    try {
                                        let prefix = phonePrefixes.filter((p: any) => p.selected == true)[0].prefix;
                                        let response = await backendAPI.updateProfileInfo(values.firstName, values.lastName, prefix + values.phone);
                                        toast.success("Info was updated successfully");
                                    } catch (e: any) {
                                        toast.error(`${Object.keys(e.response.data)[0]}: ${e.response.data[Object.keys(e.response.data)[0]]}`);
                                    }
                                }}>
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  isValid
                                  /* and other goodies */
                              }) => {
                                return (
                                    <form className={s.profile__info} onSubmit={handleSubmit}>
                                        <div className={s.profile__row}>
                                            <Input placeholder={'Selena'} label={'First Name'} labelColor={'#1A191E'} value={values.firstName} onChange={handleChange} name={"firstName"} error={touched.firstName && errors.firstName}/>
                                            <Input placeholder={'Grande'} label={'Last Name'} labelColor={'#1A191E'} value={values.lastName} onChange={handleChange} name={"lastName"} error={touched.lastName && errors.lastName}/>
                                        </div>
                                        <Input placeholder={'123 456 7890'} label={'Mobile Number'} labelColor={'#1A191E'} isPhone={true} phonePrefixes={phonePrefixes} value={values.phone} onChange={handleChange} name={"phone"} error={touched.phone && errors.phone}/>
                                        <Input placeholder={'Selenaversatile@gmail.com'} disabled={true} value={values.email} label={'Email Address'} labelColor={'#1A191E'} onChange={handleChange} name={"email"} error={touched.email && errors.email}/>
                                        <div className={s["profile__info-center"]}>
                                            <Button text={'Save'} isGradient={true} isBig={true} type={"submit"}/>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
                <ChangePassword/>
                <Notifications initialValueEmail={notificationsValues[0]} initialValueSMS={notificationsValues[1]}/>
            </div>
        </section>
    );
};

export default ProfileSettings;