import React, {ReactNode} from 'react';
import s from './Hero.module.scss';
import Facebook from './../../assets/images/icons/facebook.svg';
import Instagram from './../../assets/images/icons/instagram.svg';
import Google from './../../assets/images/icons/google.svg';
import Background from './../../assets/images/hero/background.svg';
import classNames from "classnames";

interface IHero {
    children?: ReactNode
    image?: string
    isBackground?: boolean
    isFullHeight?: boolean
    isSmall?: boolean
    height?: number
}
const Hero = ({children, image, isBackground, isFullHeight, isSmall, height}: IHero) => {
    return (
        <section className={classNames(s.hero, isBackground && s.hero_background, isFullHeight && s.hero_full, isSmall && s.hero_small)} style={height ? {height: 550, minHeight: 'auto'} : {}}>
            <div className={s.hero__background}>
                <img src={Background} alt=""/>
            </div>
            {!isBackground && <div className="container">
                <div className={s.hero__info}>
                    {children}
                </div>
                <div className={s.hero__socials}>
                    <a href="#"><img src={Facebook} alt="Facebook"/></a>
                    <a href="#"><img src={Instagram} alt="Instagram"/></a>
                    <a href="#"><img src={Google} alt="Google"/></a>
                </div>
                <div className={s.hero__image}>
                    <img src={image} alt="Hero Image"/>
                </div>
            </div>}
        </section>
    );
};

export default Hero;