import React from 'react';
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Image2 from "../../assets/images/hero/image2.png";
import Button from "../../components/UI/Button/Button";
import Companies from "../../components/Companies/Companies";
import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import Team from "../../components/Team/Team";
import Contacts from "../../components/Contacts/Contacts";
import Footer from "../../components/Footer/Footer";
import ImageSection from "../../components/ImageSection/ImageSection";
import ImageSection1 from "../../assets/images/image-section/image1.svg";
import ImageSection2 from "../../assets/images/image-section/image2.svg";
import CalendarComponent from "../../components/Calendar/Calendar";
import ProfileSettings from "../../components/ProfileSettings/ProfileSettings";
import Layout from "../../components/Layout/Layout";

const Settings = () => {
    return (
        <Layout>
            <Hero isBackground={true} height={550}/>
            <ProfileSettings/>
            {/*<Hero image={Image2}>
                <h1>Start your Day <span>Feeling</span>. <br/>
                    Calm and in Control</h1>
                <p>Simplify your workflow by focus on the right things and
                    organize your time based on the most important things
                    in your life.</p>
                <Button text={"Contact Sales"} isBig={true}/>
            </Hero>
            <Companies/>
            <ImageSection title={'A simple way to manage multiple services'} text={'With one click, view the schedule of every employee. Select\n' +
                'any or all employees to see their schedules side by side. If\n' +
                'you are using our mobile app, view your calendar in landscape\n' +
                'mode to easily manage multiple employees simultaneously.'} image={ImageSection1}/>
            <ImageSection title={'A simple way to manage multiple services'} text={'With one click, view the schedule of every employee. Select\n' +
                'any or all employees to see their schedules side by side. If\n' +
                'you are using our mobile app, view your calendar in landscape\n' +
                'mode to easily manage multiple employees simultaneously.'} image={ImageSection2} isAlt={true}/>
            <Contacts/>*/}
        </Layout>
    );
};

export default Settings;