import React from 'react';
import Layout from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import PaymentSuccessfulComponent from "../../components/PaymentSuccessful/PaymentSuccessful";

const PaymentSuccessful = () => {
    return (
        <Layout>
            <Hero isBackground={true} isFullHeight={true}/>
            <PaymentSuccessfulComponent/>
        </Layout>
    );
};

export default PaymentSuccessful;