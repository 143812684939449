import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactsComponent from "../../components/Contacts/Contacts";
import Layout from "../../components/Layout/Layout";

const Contacts = () => {
    return (
        <Layout>
            <ContactsComponent isAlt={true}/>
        </Layout>
    );
};

export default Contacts;