import s from "./ContactUs.module.scss";
import {Field} from "formik";
import Image from "./../../assets/images/contactUs/image.png";

interface IContactUs {
}

const ContactUs = ({}: IContactUs) => {
    return (
        <section className={s.contact}>
            <div className={s.contact__image}>
                <img src={Image} alt="Image"/>
            </div>
            <div className={s.contact__form}>
                <h1>Talk with a member of our
                    support team now
                </h1>
                <p>Our highly trained support team is able to quickly resolve
                    all issues you may have, to do this we request you provide
                    all relevant information</p>
                <form>
                    <div className={s["contact__form-row"]}>
                        <input placeholder={"First name"}/>
                        <input placeholder={"Last name"}/>
                    </div>
                    <input placeholder={"Business name"}/>
                    <input placeholder={"Work email address"}/>
                    <textarea placeholder={"Enter your message here"} rows={10}/>
                    <button>
                        <span>Send Message</span>
                    </button>
                </form>
            </div>
        </section>
    );
};

export default ContactUs;