import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SummaryComponent from "./../../components/Summary/Summary";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";

const Summary = () => {
    return (
        <Layout>
            <Hero isBackground={true} height={550}/>
            {/*<SummaryComponent/>*/}
        </Layout>
    );
};

export default Summary;