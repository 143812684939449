import React from 'react';
import s from './TrainerSelection.module.scss';
import Trainer from './../../assets/images/trainer-selection/trainer.png';
import Button from "../UI/Button/Button";
import classNames from "classnames";

const TrainerSelection = () => {
    return (
        <section className={s.trainers}>
            <div className="container">
                <h1>Trainer selection</h1>
                <p>Please select youre preferred trainer.</p>
                <div className={s.selection}>
                    <div className={s.selection__items}>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                        <div className={s.trainer}>
                            <div className={s.trainer__photo}>
                                <img src={Trainer} alt=""/>
                            </div>
                            <div className={s.trainer__info}>
                                <h3>Jonathan Smith</h3>
                                <span>Based in Toronto, Canada</span>
                            </div>
                            <p>“Hey! my names Jonathan, im a gym trainer and ive been training new recruits  for 7 years now. I have the skills to ensure that you get ripped”</p>
                            <Button text={'Select trainer'}/>
                        </div>
                    </div>
                    <div className={s.selection__pagination}>
                        <button className={classNames(s['selection__pagination-item'], s['selection__pagination-item_active'])}></button>
                        <button className={s['selection__pagination-item']}></button>
                        <button className={s['selection__pagination-item']}></button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TrainerSelection;