import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AboutComponent from "../../components/About/About";
import Companies from "../../components/Companies/Companies";
import Layout from "../../components/Layout/Layout";

const About = () => {
    return (
        <Layout isAltHeader={true}>
            <AboutComponent isAlt={true}/>
            <Companies isAlt={true}/>
        </Layout>
    );
};

export default About;