import s from "./../../ProfileSettings.module.scss";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import React from "react";
import {backendAPI} from "../../../../api/backendAPI";
import toast from "react-hot-toast";
import {Formik} from "formik";
import * as Yup from "yup";

interface IChangePassword {
}

const ChangePassword = ({}: IChangePassword) => {
    const PasswordSchema = Yup.object().shape({
        password: Yup.string().min(8).required('Password is required'),
        confirmPassword: Yup.string()
            .required('Required').min(8)
            .oneOf([Yup.ref('password')], 'Passwords must match'),
    });

    return (
        <div className={s.settings__block}>
            <div className={s['settings__block-info']}>
                <h2>Change Password</h2>
                <p>To make an update, enter your existing password followed by
                    a new one.</p>
            </div>
            <Formik initialValues={{password: '', confirmPassword: ''}} validationSchema={PasswordSchema}
                    onSubmit={async (values, {resetForm}) => {
                        try {
                            let response = await backendAPI.changePassword(values.password, values.confirmPassword);
                            toast.success("Password was changed successfully");
                        } catch (e: any) {
                            toast.error(`${Object.keys(e.response.data)[0]}: ${e.response.data[Object.keys(e.response.data)[0]]}`);
                        }
                    }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                  }) => {
                    return (
                        <form className={s.password}>
                            <Input placeholder={'Enter your password'} label={'Password'} value={values.password} onChange={handleChange} name={'password'} type={'password'} error={touched.password && errors.password}/>
                            <Input placeholder={'Confirm password'} label={'Password'} value={values.confirmPassword} onChange={handleChange} name={'confirmPassword'} type={'password'} error={touched.confirmPassword && errors.confirmPassword}/>
                            <Button text={'Save'} isMedium={true} type={"submit"} disabled={isSubmitting} onClick={handleSubmit}/>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ChangePassword;