import React, {useEffect, useState} from 'react';
import s from './Calendar.module.scss';
import {Calendar as ReactCalendar} from 'react-calendar';
import {backendAPI} from "../../api/backendAPI";
import dayjs from "dayjs";
import TrainerSelect from "../TrainerSelect/TrainerSelect";
import Loader from "../UI/Loader/Loader";
import Unavailable from "../Unavailable/Unavailable";
import Button from "../UI/Button/Button";
import Summary from "../Summary/Summary";
import toast from "react-hot-toast";
import Clock from './../../assets/images/icons/clock.svg';
import Popup from "../Popup/Popup";
import {useNavigate} from "react-router-dom";

const Calendar = () => {
    const navigate = useNavigate();

    const [date, setDate] = useState(new Date());
    let [times, setTimes] = useState<any>([]);
    let [events, setEvents] = useState<any>([]);
    let [availableTrainers, setAvailableTrainers] = useState<any>([]);
    let [isLoading, setIsLoading] = useState(false);
    let [step, setStep] = useState(0);
    let [summaryData, setSummaryData] = useState<any>();
    let [purchaseURL, setPurchaseURL] = useState("");
    let [isPopupActive, setIsPopupActive] = useState(false);

    let fetchData = async () => {
        setIsLoading(true);
        let response = await backendAPI.fetchAvailableEvents();
        setEvents(response.map((i: any, index: number) => index == 0 ? {...i, selected: true} : i));
        setIsLoading(false);
    };

    let fetchAvailableTrainers = async () => {
        setIsLoading(true);
        let response = await backendAPI.fetchAvailableTrainers(events.filter((e: any) => e.selected)[0].event_id, dayjs(date).format("YYYY-MM-DD"))
        response = Object.values(response);
        if (response.length) {
            response[0].selected = true;
        }
        setAvailableTrainers(response);
        setIsLoading(false);
    };

    let fetchSummary = async () => {
        setIsLoading(true);
        try {
            let response = await backendAPI.fetchSummary(events.filter((e: any) => e.selected)[0].event_id,
                availableTrainers.filter((t: any) => t.selected)[0].trainer_id,
                dayjs(date).format("YYYY-MM-DD"),
                times.filter((t: any) => t.selected)[0].time);
            let purchaseResponse = await backendAPI.fetchPurchase(events.filter((e: any) => e.selected)[0].event_id,
                availableTrainers.filter((t: any) => t.selected)[0].trainer_id,
                dayjs(date).format("YYYY-MM-DD"),
                times.filter((t: any) => t.selected)[0].time);
            setPurchaseURL(purchaseResponse.session_url);
            setSummaryData(response);
            setStep(1);
        } catch (e: any) {
            toast.error(e.response.data.error);
        }
        setIsLoading(false);
    };

    let payCash = async () => {
        try {
            let purchaseResponse = await backendAPI.fetchPurchase(events.filter((e: any) => e.selected)[0].event_id,
                availableTrainers.filter((t: any) => t.selected)[0].trainer_id,
                dayjs(date).format("YYYY-MM-DD"),
                times.filter((t: any) => t.selected)[0].time, true);
            navigate('/payment-successful');
        } catch (e: any) {
            toast.error(e.response.data.error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (events.filter((e: any) => e.selected).length) {
            fetchAvailableTrainers();
        }
    }, [date, events]);

    useEffect(() => {
        if (availableTrainers.length) {
            let availableTimes: any[] = [];
            let trainerTimes = availableTrainers.filter((t: any) => t.selected)[0].availability;
            trainerTimes.map((t: any) => {
               availableTimes.push(...t.time_slots);
            });
            setTimes(availableTimes.map((t: any) => ({time: t})));
        } else {
            setTimes([]);
        }
    }, [availableTrainers]);

    if (step == 1) {
        if (isLoading) {
            return <Loader isFull={true}/>
        }
        return (<Summary data={summaryData} url={purchaseURL} payCash={payCash}/>);
    }

    return (
        <>
            <Popup isActive={isPopupActive} closeHandle={() => {setIsPopupActive(false)}}>
                <div className={s.calendar__popup}>
                    <h3>{events.length && events.filter((e: any) => e.selected)[0].title}</h3>
                    <p>{events.length && events.filter((e: any) => e.selected)[0].description}</p>
                </div>
            </Popup>
            <section className={s.calendar}>
                <div className={"container"}>
                    <div className={s.calendar__block}>
                        <div className={s.calendar__calendar}>
                            <ReactCalendar onChange={setDate} value={date} locale={'en-EN'} tileDisabled={({ activeStartDate, date, view }) => {
                                let currentDate = new Date();
                                currentDate.setDate(currentDate.getDate() - 1);
                                return date < currentDate
                            }} formatDay={(locale, date) => date.getDate().toLocaleString('en-EN')} formatShortWeekday={(locale, date) => date.toLocaleDateString('en-US',{weekday: 'long'}).slice(0, 2)}/>
                        </div>
                        <div className={s.calendar__select}>
                            {isLoading ? <Loader/> : <>
                                <div className={s['calendar__select-block']}>
                                    <div className={s['calendar__select-block__header']}>
                                        <span>Select Time</span>
                                    </div>
                                    {times.length ? <div className={s.calendar__times}>
                                        {times.map((t: any) => (<label className={s['calendar__times-item']} key={t.time}>
                                            <input type="radio" name={"times"} value={t.time} checked={t.selected} onChange={(e) => {
                                                setTimes(times.map((time: any) => {
                                                    if (time.time == e.currentTarget.value) {
                                                        return {...time, selected: true};
                                                    }
                                                    return {...time, selected: false};
                                                }));
                                            }}/>
                                            <div>
                                                <span>{t.time}</span>
                                            </div>
                                        </label>))}
                                    </div> : <Unavailable title={"No times"} text={"No times"}/>}
                                </div>
                                <div className={s['calendar__select-block']}>
                                    <div className={s['calendar__select-block__item']}>
                                        <div className={s['calendar__select-block__header']}>
                                            <span>Select Service</span>
                                            <div className={s['calendar__select-block__length']}>
                                                <img src={Clock} alt=""/>
                                                <span>{events.length && events.filter((e: any) => e.selected)[0].length / 60} mins.</span>
                                            </div>
                                        </div>
                                        <div className={s['calendar__service-wrapper']}>
                                            <select className={s['calendar__service']} onChange={(event) => {
                                                setEvents(events.map((e: any) => {
                                                    if (e.event_id == event.currentTarget.value) {
                                                        return {...e, selected: true};
                                                    }
                                                    return {...e, selected: false};
                                                }));
                                            }} defaultValue={events.length && events.filter((e: any) => e.selected)[0].event_id}>
                                                {events.map((s: any) => (<option value={s.event_id} key={s.event_id}>{s.title}</option>))}
                                            </select>
                                        </div>
                                        <div className={s['calendar__select-block__desc']}>
                                            <button onClick={() => {setIsPopupActive(true)}}>View description</button>
                                        </div>
                                    </div>
                                    <div className={s['calendar__select-block__item']}>
                                        <span>Trainer selection</span>
                                        <TrainerSelect trainers={availableTrainers} isSingle={true} setTrainers={setAvailableTrainers}/>
                                    </div>
                                    {times.filter((t: any) => t.selected).length > 0 && <div className={s['calendar__select-block__item']}>
                                        <Button text={"Book Now"} onClick={() => {fetchSummary()}}/>
                                    </div>}
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Calendar;