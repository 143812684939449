import React from 'react';
import s from './PaymentSuccessful.module.scss';
import Check from './../../assets/images/icons/check.svg';
import {useNavigate} from "react-router-dom";
import Button from "../UI/Button/Button";

const PaymentSuccessful = () => {
    let navigate = useNavigate();

    return (
        <section className={s.successful}>
            <div className={"container"}>
                <div className={s.successful__block}>
                    <div>
                        <img src={Check} alt=""/>
                    </div>
                    <strong>Payment Successful!</strong>
                    <span>Transaction Number: 123456789</span>
                </div>
                <Button text={"Return to Dashboard"} onClick={() => {navigate("/dashboard")}}/>
            </div>
        </section>
    );
};

export default PaymentSuccessful;