import React from 'react';
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Bookings from "../../components/Bookings/Bookings";
import Layout from "../../components/Layout/Layout";

const UpcomingBookings = () => {
    return (
        <Layout>
            <Hero isBackground={true}/>
            <Bookings/>
        </Layout>
    );
};

export default UpcomingBookings;