import React, {useEffect, useState} from 'react';
import s from './Bookings.module.scss';
import Service from './../../assets/images/bookings/service.jpg';
import CalendarIcon from './../../assets/images/icons/calendar.svg';
import Clock from './../../assets/images/icons/clock.svg';
import Arrow from './../../assets/images/icons/arrow.svg';
import Button from "../UI/Button/Button";
import classNames from "classnames";
import {backendAPI} from "../../api/backendAPI";
import Loader from "../UI/Loader/Loader";
import dayjs from "dayjs";
import Unavailable from "../Unavailable/Unavailable";

const Bookings = () => {
    let [data, setData] = useState<any>([]);
    let [isLoading, setIsLoading] = useState(false);

    let fetchData = async () => {
        setIsLoading(true);
        let response = await backendAPI.fetchBookings();
        if (response.length) {
            response[0].selected = true;
        }
        setData(response);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    let selectedBooking;

    if (!isLoading && data.length) {
        selectedBooking = data.filter((b: any) => b.selected)[0];
    }

    let handleClick = (id: number) => {
        setData(data.map((d: any) => {
            if (d.id == id) {
                return {...d, selected: true}
            }
            return {...d, selected: false}
        }));
    };

    if (isLoading) {
        return <Loader isFull={true}/>
    }

    if (data.length == 0) {
        return <Unavailable title={"No bookings"} text={"No bookings"} isFull={true} isGreyTitle={true}/>
    }

    return (
        <section className={s.bookings}>
            <div className="container">
                <div className={s.bookings__block}>
                    <div className={s.bookings__col}>
                        <div className={s['booking-list']}>
                            <div className={s['booking-list__header']}>
                                <h3>Booking List</h3>
                            </div>
                            <div className={s['booking-list__items']}>
                                {data.map((i: any) => (
                                    <div className={s['booking-list__items-item']} onClick={() => {handleClick(i.id)}} key={i.id}>
                                        <div className={s['booking-list__items-item__image']}>
                                            <img src={Service} alt=""/>
                                        </div>
                                        <div className={s['booking-list__items-item__info']}>
                                            <time>
                                                <img src={Clock} alt=""/>
                                                <span>{dayjs(i.start_time).format("hh:mm A")}</span>
                                            </time>
                                            <span>{i.event.title}</span>
                                            <div className={s['booking-list__items-item__info-service']}>
                                                <span>{i.event.trainer}</span>
                                            </div>
                                        </div>
                                        <div className={s['booking-list__items-item__arrow']}>
                                            <img src={Arrow} alt=""/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={s.booking}>
                        <div className={s.booking__header}>
                            <div className={s['booking__header-image']}>
                                <img src={Service} alt=""/>
                            </div>
                            <div className={s['booking__header-info']}>
                                <div className={s['booking__header-info__date']}>
                                    <time>
                                        <img src={CalendarIcon} alt=""/>
                                        <span>{dayjs(selectedBooking.start_time).format("DD MMM, YYYY")}</span>
                                    </time>
                                    <time>
                                        <img src={Clock} alt=""/>
                                        <span>{dayjs(selectedBooking.start_time).format("hh:mm A")}</span>
                                    </time>
                                </div>
                                <span>{selectedBooking.event.title}</span>
                                <div className={s['booking__header-info__service']}>
                                    <span>{selectedBooking.event.trainer}</span>
                                    {/*<span>Nail Art & Makeup</span>*/}
                                </div>
                                {/*<div className={s['booking__header-info__buttons']}>
                                    <Button text={'Cancel Booking'} withBorder={true} isGradient={true}/>
                                    <Button text={'Reschedule Booking'}/>
                                </div>*/}
                                <div className={s['booking__header-info__rating']}>
                                    {/*<div className={s['booking__header-info__rating-stars']}>
                                        <img src={Star} alt=""/>
                                        <img src={Star} alt=""/>
                                        <img src={Star} alt=""/>
                                        <img src={Star} alt=""/>
                                        <img src={Star} alt=""/>
                                    </div>*/}
                                    <span>{selectedBooking.event.length / 60} mins.</span>
                                </div>
                            </div>
                        </div>
                        <div className={s.booking__body}>
                            <div className={s.booking__description}>
                                <span>Description</span>
                                <p>{selectedBooking.event.description}</p>
                            </div>
                            {/*<div className={s.booking__location}>
                                <div>
                                    <img src={Location} alt=""/>
                                    <span>Location</span>
                                </div>
                                <p>50 Harrington Road, London (South Kensington), England. <a href="#">See Direction</a></p>
                            </div>*/}
                            <div className={s.booking__summary}>
                                {/*<span>Payment Summary</span>*/}
                                <div className={s['booking__summary-items']}>
                                    {/*<div className={s['booking__summary-items__item']}>
                                        <div>
                                            <span>Nail Art & Makeup</span>
                                            <span>30 mins</span>
                                        </div>
                                        <span>$150.00</span>
                                    </div>
                                    <div className={s['booking__summary-items__item']}>
                                        <div>
                                            <span>Nail Art & Makeup</span>
                                            <span>30 mins</span>
                                        </div>
                                        <span>$150.00</span>
                                    </div>*/}
                                    <div className={classNames(s['booking__summary-items__item'], s['booking__summary-items__item_total'])}>
                                        <div>
                                            <span>Total Amount</span>
                                        </div>
                                        <span>${(selectedBooking.event.price / 100).toLocaleString("AU-au")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Bookings;