import {createAsyncThunk} from "@reduxjs/toolkit";
import {authAPI, backendAPI} from "../api/backendAPI";

// AUTH
export const auth = createAsyncThunk(
    'auth/auth',
    async (_, thunkApi) => {
        return await authAPI.auth();
    }
)

export const login = createAsyncThunk(
    'auth/login',
    async ({email, password}: {email: string, password: string}, thunkApi) => {
        try {
            return await authAPI.login(email, password);
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data);
        }
    }
)

export const googleLogin = createAsyncThunk(
    'auth/googleLogin',
    async ({credential}: {credential: string}, thunkApi) => {
        try {
            return await authAPI.googleLogin(credential);
        } catch (err: any) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data);
        }
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, thunkApi) => {
        return await authAPI.logout();
    }
)

// STAFF
export const fetchStaff = createAsyncThunk(
    'staff/fetch',
    async (_, thunkApi) => {
        return await backendAPI.fetchStaff();
    }
)