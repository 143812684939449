import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DashboardComponent from "../../components/Dashboard/Dashboard";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";

const Dashboard = () => {
    return (
        <Layout>
            <Hero isBackground={true} height={550}/>
            <DashboardComponent/>
        </Layout>
    );
};

export default Dashboard;