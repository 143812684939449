import React from 'react';
import s from './Button.module.scss';
import classNames from "classnames";

interface IButton {
    text: string
    withBorder?: boolean
    isWhite?: boolean
    isBig?: boolean
    isGradient?: boolean
    isRed?: boolean
    className?: string
    onClick?: any
    disabled?: boolean
    rounded?: boolean
    dark?: boolean
    isSmall?: boolean
    isMedium?: boolean
    type?: "button" | "submit" | "reset" | undefined
}
const Button = ({text, withBorder, isBig, isWhite, isGradient, isRed, className, onClick, disabled, rounded, dark, isSmall, type, isMedium}: IButton) => {
    return (
        <button className={classNames(s.button, withBorder && s['button_border'], isBig && s['button_big'], isRed && s['button_red'], isWhite && s['button_white'], rounded && s['button_rounded'], dark && s['button_dark'], isSmall && s['button_small'], isMedium && s['button_medium'], className)} onClick={onClick} type={type?.length ? type : "button"} disabled={disabled}>
            <span>{text}</span>
        </button>
    );
};

export default Button;