import React from 'react';
import s from './Loader.module.scss';
import classNames from "classnames";

interface ILoader {
    isFull?: boolean
}
const Loader = ({isFull}: ILoader) => {
    return (
        <div className={classNames(s.loader, isFull && s.loader_full)}>
            <span className={s.loader__item}></span>
        </div>
    );
};

export default Loader;