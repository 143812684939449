import React, {useEffect, useState} from 'react';
import s from './Dashboard.module.scss';
import Icon from './../../assets/images/icons/clock.svg';
import Button from "../UI/Button/Button";
import classNames from "classnames";
import { ReactComponent as Arrow } from './../../assets/images/icons/arrow_down.svg';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import dayjs from "dayjs";
import Timer from "./components/Timer";
import {backendAPI} from "../../api/backendAPI";
import Loader from "../UI/Loader/Loader";
import Popup from "../Popup/Popup";
import LinkedTrainers from "../LinkedTrainers/LinkedTrainers";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    let [data, setData] = useState<any>({
        linked_trainers: {
            trainers: []
        }
    });
    let navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(true);
    let [isPopupActive, setIsPopupActive] = useState(false);

    let fetchData = async () => {
        setIsLoading(true);
        setData(await backendAPI.fetchCustomerDashboard());
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Popup isActive={isPopupActive} closeHandle={() => {setIsPopupActive(false)}}>
                <LinkedTrainers items={data.linked_trainers.trainers}/>
            </Popup>
            <section className={s.dashboard}>
                <div className={"container"}>
                    <h2>User dashboard</h2>
                    {isLoading ? <Loader isFull={true}/> : <>
                        <div className={s.dashboard__row}>
                            <div className={s.dashboard__block}>
                                <div className={s['dashboard__block-title']}>
                                    <img src={Icon} alt=""/>
                                    <span>Upcoming Bookings</span>
                                </div>
                                {Object.keys(data.upcoming_booking).length > 0 ? <p>You have booked <span>1 session</span> which has been selected to commence on <span>{dayjs(data.upcoming_booking.start_time).format('MMMM DD, YYYY')}</span></p> :
                                    <p>There are no previous bookings, get started and book today!</p>}
                                <div className={s['dashboard__block-buttons']}>
                                    <Button text={'View bookings'} onClick={() => {navigate("/bookings")}}/>
                                    {/*<a href="#"><span>Learn more</span><Arrow/></a>*/}
                                </div>
                            </div>
                            <div className={s.dashboard__block}>
                                <div className={s['dashboard__block-title']}>
                                    <img src={Icon} alt=""/>
                                    <span>Last Booking</span>
                                </div>
                                {Object.keys(data.last_booking).length > 0 ? <p>You last booking was <span>{data.last_booking.event_title}</span> with <span>{data.last_booking.trainer_name}</span> on <span>{dayjs(data.last_booking.timestamp).format('MMMM DD, YYYY')}</span></p> :
                                    <p>There are no previous bookings, get started and book today!</p>}
                                <div className={s['dashboard__block-buttons']}>
                                    <Button text={'Create new booking'} onClick={() => {navigate("/calendar")}}/>
                                    {/*<a href="#"><span>Learn more</span><Arrow/></a>*/}
                                </div>
                            </div>
                        </div>
                        <div className={s.dashboard__row}>
                            <div className={s.dashboard__block}>
                                <div className={s['dashboard__block-title']}>
                                    <img src={Icon} alt=""/>
                                    <span>Invoices</span>
                                </div>
                                <p>You have paid a total of <span>{data.total_invoices} {data.total_invoices == 1 ? "invoice" : "invoices"}</span> since you registered on <span>{dayjs(data.user_created_at).format('MMMM DD, YYYY')}</span></p>
                                <div className={s['dashboard__block-buttons']}>
                                    <Button text={'View payments'} onClick={() => {navigate("/payment-history")}}/>
                                    {/*<a href="#"><span>Learn more</span><Arrow/></a>*/}
                                </div>
                            </div>
                            <div className={s.dashboard__block}>
                                <div className={s['dashboard__block-title']}>
                                    <img src={Icon} alt=""/>
                                    <span>Notifications</span>
                                </div>
                                <p>Email notifications: <span>{data.notifications["notifications.email"]}</span><br/>
                                    SMS notifications: <span>{data.notifications["notifications.sms"]}</span><br/>
                                    Phone Number: <span>{data.notifications["phone_number"]}</span></p>
                                <div className={s['dashboard__block-buttons']}>
                                    <Button text={'Edit settings'} onClick={() => {navigate("/settings")}}/>
                                    {/*<a href="#"><span>Learn more</span><Arrow/></a>*/}
                                </div>
                            </div>
                        </div>
                        <div className={s.dashboard__row}>
                            <div className={classNames(s.dashboard__block)}>
                                <div className={s['dashboard__block-content']}>
                                    <div className={s['dashboard__block-title']}>
                                        <img src={Icon} alt=""/>
                                        <span>Linked Trainers</span>
                                    </div>
                                    <p>Your account is linked to <span>{data.linked_trainers.trainers.length} {data.linked_trainers.trainers.length == 1 ? "trainer" : "trainers"}</span>, giving you access to <span>50 services</span>.</p>
                                    <div className={s['dashboard__block-buttons']}>
                                        <Button text={'View linked trainers'} onClick={() => {setIsPopupActive(true)}}/>
                                        {/*<a href="#"><span>Learn more</span><Arrow/></a>*/}
                                    </div>
                                </div>
                            </div>
                            <Timer/>
                        </div>
                    </>}
                </div>
            </section>
        </>
    );
};

export default Dashboard;