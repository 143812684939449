import React, {useEffect, useState} from 'react';
import s from './Header.module.scss';
import Logo from './../../assets/images/logo.png';
import Button from "../UI/Button/Button";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import {useAppDispatch} from "../../store/hooks";
import {logout} from "../../store/actionCreators";

interface IHeader {
    isAlt?: boolean
}

let menuItems = [
    {link: "/dashboard", text: "Dashboard"},
    {link: "/payment-history", text: "Invoices"},
    {link: "/bookings", text: "Bookings"},
    {link: "/contact-us", text: "Contact Us"},
    {link: "/settings", text: "Settings"},
];

const Header = ({isAlt}: IHeader) => {
    let [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
    let [isOpened, setIsOpened] = useState(false);

    let dispatch = useAppDispatch();

    useEffect(() => {
        window.addEventListener('resize', () => setIsMobile(window.innerWidth < 1280));
        return () => {
            window.removeEventListener('resize', () => setIsMobile(window.innerWidth < 1280));
        }
    }, []);

    return (
        <header className={classNames(s.header, isOpened && s.header_opened, isAlt && s.header_alt)}>
            <div className="container">
                <NavLink to={"/dashboard"}><img src={Logo} alt="" className={s.header__logo}/></NavLink>
                {!isMobile ? <>
                    <ul className={s['header__menu']}>
                        {menuItems.map(i => (<li className={classNames(s['header__menu-item'])} key={i.link}><NavLink to={i.link}>
                            {({ isActive}) => (
                                isActive ? <>
                                    <span>{i.text}</span>
                                    <div className={s['header__menu-item__dot']}>
                                        <div></div>
                                    </div>
                                </> : <span>{i.text}</span>
                            )}
                        </NavLink></li>))}
                    </ul>
                    <div className={s['header__buttons']}>
                        <NavLink to={"/calendar"}><Button text={'Book now'}/></NavLink>
                        <Button text={'Logout'} isRed={true} onClick={async () => {
                            dispatch(logout());
                        }}/>
                    </div>
                </> : <>
                    <button className={classNames(s.header__burger, isOpened && s['header__burger_opened'])} onClick={() => setIsOpened(!isOpened)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    {isOpened && <ul className={s['header__menu']}>
                        {menuItems.map(i => (<li className={classNames(s['header__menu-item'])} key={i.link}><NavLink to={i.link}>
                            {({ isActive}) => (
                                isActive ? <>
                                    <span>{i.text}</span>
                                    <div className={s['header__menu-item__dot']}>
                                        <div></div>
                                    </div>
                                </> : <span>{i.text}</span>
                            )}
                        </NavLink></li>))}
                        <li className={classNames(s['header__menu-item'])}><NavLink to={"/calendar"}><Button text={'Book now'}/></NavLink></li>
                        <li className={classNames(s['header__menu-item'])}><Button text={'Logout'} isRed={true} onClick={async () => {
                            dispatch(logout());
                        }}/></li>
                    </ul> }
                </>}
            </div>
        </header>
    );
};

export default Header;