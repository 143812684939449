import React from 'react';
import classNames from "classnames";
import s from "../../Auth.module.scss";
import {Formik} from "formik";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {authAPI, backendAPI} from "../../../../api/backendAPI";
import toast from "react-hot-toast";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";

const PasswordReset = () => {
    let {uID = "", token = ""} = useParams();
    let navigate = useNavigate();

    const resetPasswordSchema = Yup.object().shape({
        password: Yup.string().min(8).required('Password is required'),
        passwordConfirm: Yup.string()
            .required('Required').min(8)
            .oneOf([Yup.ref('password')], 'Passwords must match'),
    });

    return (
        <div className={classNames(s['auth__block-container'], s['auth__block-container_start'])}>
            <div className={s['auth__block-info']}>
                <h2>Forgot Password</h2>
                <p>Please enter your new password below</p>
            </div>
            <Formik initialValues={{password: '', passwordConfirm: '' }} validationSchema={resetPasswordSchema} onSubmit={async (values, { setSubmitting }) => {
                try {
                    await authAPI.resetPassword(values.password, values.passwordConfirm, uID, token);
                    toast.success("Reset was successful, login please");
                    navigate("/login");
                } catch (e: any) {
                    toast.error(e.response.data[Object.keys(e.response.data)[0]][0]);
                }
            }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form className={s['auth__block-form']} onSubmit={handleSubmit}>
                        <Input placeholder={'Enter new password'} label={'New password'} value={values.password} type={"password"} isHideable={true} onChange={handleChange} name={'password'}/>
                        <Input placeholder={'Confirm new password'} label={'Confirm new password'} value={values.passwordConfirm} type={"password"} onChange={handleChange} name={'passwordConfirm'}/>
                        <Button text={'Confirm changes'} type={'submit'} isBig={true} disabled={isSubmitting}/>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default PasswordReset;