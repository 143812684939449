import React, {useState} from 'react';
import s from './ToggleButton.module.scss';

interface IToggleButton {
    checked: boolean
    onChange: any
    label?: string
    name?: string
}
const ToggleButton = ({checked, onChange, label, name}: IToggleButton) => {
    return (
        <div className={s.toggle}>
            <label className={s.switch}>
                <input type="checkbox" name={name} checked={checked} onChange={onChange}/>
                <span className={s.switch__slider}></span>
            </label>
            {label && <span>{label}</span>}
        </div>
    );
};

export default ToggleButton;