import React from 'react';
import s from './Services.module.scss';
import Icon1 from './../../assets/images/services/icon1.svg';
import Icon2 from './../../assets/images/services/icon2.svg';
import Icon3 from './../../assets/images/services/icon3.svg';
import Arrow from '../../assets/images/icons/arrow.svg';
import Button from "../UI/Button/Button";
import classNames from "classnames";

interface IServices {
    isAlt?: boolean
}
const Services = ({isAlt}: IServices) => {
    return (
        <section className={classNames(s.services, isAlt && s.services_alt)}>
            <div className="container">
                <div className={s.services__text}>
                    <h2>Our <br/> <span>Services</span></h2>
                    <p>We work towards creating products that have an edge over
                        the competition, ensuring all our services are high-quality and
                        leave the customer satisfied.</p>
                </div>
                <div className={s.services__items}>
                    {isAlt ? <>
                        <a href="#" className={classNames(s['services__items-item'], s['services__items-item_alt'])}>
                            <div className={s['services__items-item__info']}>
                                <img src={Icon1} className={s['services__items-item__icon']} alt="Website Development"/>
                                <h3>Website <br/> Development</h3>
                            </div>
                            <p>We are a team of skilled professionals who  specialize in creating, designing, and develop- ing websites for individuals and businesses. We  provide a range of services such as website de- sign, website development, website maintenance,  and website hosting</p>
                        </a>
                        <a href="#" className={classNames(s['services__items-item'], s['services__items-item_alt'])}>
                            <div className={s['services__items-item__info']}>
                                <img src={Icon2} className={s['services__items-item__icon']} alt="Website Development"/>
                                <h3>Store <br/> Management</h3>
                            </div>
                            <p>We specialize in helping online businesses to achieve their full potential by providing a range  of tools and  services to increase sales, optimize  operations, and enhance customer experiences.</p>
                        </a>
                        <a href="#" className={classNames(s['services__items-item'], s['services__items-item_alt'])}>
                            <div className={s['services__items-item__info']}>
                                <img src={Icon3} className={s['services__items-item__icon']} alt="Website Development"/>
                                <h3>App <br/> Development</h3>
                            </div>
                            <p>We are a team of experienced developers who  are  dedicated to creating mobile applications  that are innovative, intuitive, and engaging. Our  mission is to help businesses and entrepreneurs  bring their ideas to life by providing customized  app development services that meet their  specific needs.</p>
                        </a>
                    </> : <>
                        <a href="#" className={s['services__items-item']}>
                            <img src={Icon1} className={s['services__items-item__icon']} alt="Website Development"/>
                            <h3>Website Development</h3>
                            <p>We host Develop and Design
                                Website.</p>
                            <img src={Arrow} alt="Arrow"/>
                        </a>
                        <a href="#" className={s['services__items-item']}>
                            <img src={Icon1} className={s['services__items-item__icon']} alt="Website Development"/>
                            <h3>Website Development</h3>
                            <p>We host Develop and Design
                                Website.</p>
                            <img src={Arrow} alt="Arrow"/>
                        </a>
                        <a href="#" className={s['services__items-item']}>
                            <img src={Icon1} className={s['services__items-item__icon']} alt="Website Development"/>
                            <h3>Website Development</h3>
                            <p>We host Develop and Design
                                Website.</p>
                            <img src={Arrow} alt="Arrow"/>
                        </a>
                    </>}

                </div>
                {isAlt ? <Button text={'Contact us'} isBig={true} rounded={true}/> : <Button text={'More Service'} isBig={true}/>}
            </div>
        </section>
    );
};

export default Services;