import React from 'react';
import s from './LinkedTrainers.module.scss';
import Photo from "./../../assets/images/services/trainer.png";

interface ILinkedTrainers {
    items: Array<any>
}
const LinkedTrainers = ({items}: ILinkedTrainers) => {
    return (
        <div className={s.trainers}>
            <strong>Linked trainers</strong>
            <p>Don't see your trainer on here? Ask them for their invite code.</p>
            <div className={s.trainers__block}>
                <span>{items.length} {items.length == 1 ? "trainer" : "trainers"}</span>
                <div className={s.trainers__items}>
                    {items.map((i: any) => (<div className={s["trainers__items-item"]} key={i.name + i.gym_name}>
                        <div className={s["trainers__items-item__photo"]}>
                            <img src={Photo} alt=""/>
                        </div>
                        <div className={s["trainers__items-item__info"]}>
                            <strong>{i.name}</strong>
                            <span>{i.gym_name}</span>
                        </div>
                    </div>))}
                </div>
            </div>
        </div>
    );
};

export default LinkedTrainers;