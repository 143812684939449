import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ServicesComponent from "../../components/Services/Services";
import Layout from "../../components/Layout/Layout";
const Services = () => {
    return (
        <Layout>
            <ServicesComponent isAlt={true}/>
        </Layout>
    );
};

export default Services;