import React, {ReactNode, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {auth} from "./store/actionCreators";
import {Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Services from "./pages/Services/Services";
import {setIsLoading} from "./store/reducers/authSlice";
import {backendAPI, setupInterceptors} from "./api/backendAPI";
import toast from "react-hot-toast";
import Home from "./pages/Home/Home";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Calendar from "./pages/Calendar/Calendar";
import AuthComponent from "./components/AuthComponent/AuthComponent";
import PaymentHistory from "./pages/PaymentHistory/PaymentHistory";
import CustomerCalendar from "./pages/CustomerCalendar/CustomerCalendar";
import Loader from "./components/UI/Loader/Loader";
import UpcomingBookings from "./pages/UpcomingBookings/UpcomingBookings";
import Settings from "./pages/Settings/Settings";
import Contacts from "./pages/Contacts/Contacts";
import About from "./pages/About/About";
import Summary from "./pages/Summary/Summary";
import Dashboard from "./pages/Dashboard/Dashboard";
import TrainerSelection from "./pages/TrainerSelection/TrainerSelection";
import PaymentSuccessful from "./pages/PaymentSuccessful/PaymentSuccessful";
import ContactUs from "./components/ContactUs/ContactUs";
import PasswordReset from "./pages/PasswordReset/PasswordReset";

const App = () => {
    const dispatch = useAppDispatch();
    const authData = useAppSelector(state => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let inviteCode = searchParams.get("invite");
        if (inviteCode) {
            localStorage.setItem("invite", inviteCode);
        }
        if (localStorage.getItem("token")) {
            dispatch(auth());
        } else {
            dispatch(setIsLoading(false));
        }
        setupInterceptors(navigate, location, () => {
            dispatch(auth())
        });
    }, []);

    let redeemInvite = async (inviteCode: string) => {
        try {
            let response = await backendAPI.redeemInvite(inviteCode);
            navigate("/dashboard");
            toast.success(response.message);
        } catch (e: any) {
            navigate("/dashboard");
            toast.error(e.response.data.error);
        } finally {
            localStorage.removeItem("invite");
        }
    }

    if (authData.data.isAuth && localStorage.getItem("invite")) {
        let inviteCode: string = localStorage.getItem("invite") || "";
        redeemInvite(inviteCode);
    }

    let pages = [
        {
            path: "/",
            element: <Home/>,
        },
        {
            path: "/login",
            element: <Login/>,
        },
        {
            path: "/signup",
            element: <Signup/>,
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword/>,
        },
        {
            path: "/auth/password/reset/confirm/:uID/:token",
            element: <PasswordReset/>,
            withoutAuth: true
        },
        {
            path: "/calendar",
            element: <Calendar/>,
        },
        {
            path: "/payment-history",
            element: <PaymentHistory/>,
        },
        {
            path: "/bookings",
            element: <UpcomingBookings/>,
        },
        {
            path: "/customer-calendar",
            element: <CustomerCalendar/>,
        },
        {
            path: "/contacts",
            element: <Contacts/>,
        },
        {
            path: "/services",
            element: <Services/>,
        },
        {
            path: "/about",
            element: <About/>,
        },
        {
            path: "/summary",
            element: <Summary/>,
        },
        {
            path: "/dashboard",
            element: <Dashboard/>,
        },
        {
            path: "/trainers",
            element: <TrainerSelection/>,
        },
        {
            path: "/settings",
            element: <Settings/>,
        },
        {
            path: "/payment-successful",
            element: <PaymentSuccessful/>,
        },
        {
            path: "/contact-us",
            element: <ContactUs/>,
        }
    ];

    if (authData.isLoading) {
        return <Loader isFull={true}/>;
    }

    return window.self === window.top ?
        <Routes>{pages.map((p: { path: string, element: ReactNode, withoutAuth?: boolean }) => (
            <Route path={p.path} element={p.withoutAuth ? p.element : <AuthComponent>{p.element}</AuthComponent>}
                   key={p.path}/>))}</Routes> : null;
};

export default App;