import React from 'react';
import s from './Popup.module.scss';
import classNames from "classnames";
import Cross from "./../../assets/images/icons/clock.svg";

interface IPopup {
    children: React.ReactNode
    isActive: boolean
    closeHandle: () => void
    title?: string
}
const Popup = ({children, isActive, closeHandle, title}: IPopup) => {
    return (
        <div className={classNames(s.popup, isActive && s.popup_active)} onClick={closeHandle}>
            <div className={classNames(s.popup__content, title && s.popup__content_titled)} onClick={(event) => {event.stopPropagation();}}>
                <button className={s.popup__close} onClick={closeHandle}>
                    <img src={Cross} alt=""/>
                </button>
                {title && <div className={s.popup__header}>
                    <span>{title}</span>
                </div>}
                {children}
            </div>
        </div>
    );
};

export default Popup;