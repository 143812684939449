import React, {useEffect, useState} from 'react';
import s from './History.module.scss';
import Chip from './../../assets/images/payment-history/chip.svg'
import MasterCard from './../../assets/images/payment-history/mastercard.svg'
import PayPal from './../../assets/images/payment-history/paypal.svg'
import Plus from './../../assets/images/icons/plus.svg'
import Download from './../../assets/images/icons/download.svg'
import Pay from "../../pages/Pay/Pay";
import classNames from "classnames";
import {backendAPI} from "../../api/backendAPI";
import dayjs from "dayjs";
import Loader from "../UI/Loader/Loader";

const History = () => {
    let [data, setData] = useState<any>({
        invoices: []
    });
    let [isLoading, setIsLoading] = useState(false);

    let fetchData = async () => {
        setIsLoading(true);
        let response = await backendAPI.fetchInvoices();
        setData(response);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <section className={s['payment-history']}>
            <div className="container">
                <div className={s.history}>
                    <div className={s.history__info}>
                        <h2>Payment History</h2>
                        <p>Update your Payment Information or change your plans according to your needs.</p>
                    </div>
                    {/*<div className={s.history__block}>
                        <h3>My Plans</h3>
                        <p>Change your plan based on your needs</p>
                        <div className={s.history__plans}>
                            <label className={s['history__plans-item']}>
                                <input type="radio" name="historyPlan" id=""/>
                                <div>
                                    <div className={s['history__plans-item__block']}>
                                        <span
                                            className={s['history__plans-item__price']}><span>50<small>$</small></span></span>
                                        <div className={s['history__plans-item__info']}>
                                            <div>
                                                <span>Basic Plan</span>
                                                <div className={s['history__plans-item__info-period']}><span>Billed Monthly</span>
                                                </div>
                                            </div>
                                            <p>Our most popular plan for small teams.</p>
                                        </div>
                                    </div>
                                    <div className={s['history__plans-item__radio']}></div>
                                </div>
                            </label>
                            <label className={s['history__plans-item']}>
                                <input type="radio" name="historyPlan" id=""/>
                                <div>
                                    <div className={s['history__plans-item__block']}>
                                        <span
                                            className={s['history__plans-item__price']}><span>50<small>$</small></span></span>
                                        <div className={s['history__plans-item__info']}>
                                            <div>
                                                <span>Basic Plan</span>
                                                <div className={s['history__plans-item__info-period']}><span>Billed Monthly</span>
                                                </div>
                                            </div>
                                            <p>Our most popular plan for small teams.</p>
                                        </div>
                                    </div>
                                    <div className={s['history__plans-item__radio']}></div>
                                </div>
                            </label>
                        </div>
                    </div>*/}
                    {/*<div className={s.history__block}>
                        <h3>Payment Method</h3>
                        <p>Change how you pay your plan</p>
                        <div className={s.history__methods}>
                            <div className={s['history__methods-item']}>
                                <div className={s['history__methods-item__header']}>
                                    <img src={Chip} alt=""/>
                                    <img src={MasterCard} alt=""/>
                                </div>
                                <div className={s['history__methods-item__info']}>
                                    <span>Credit Card / Debit Card</span>
                                    <span>XXXX XXXX XXXX 3550</span>
                                </div>
                            </div>
                            <div className={s['history__methods-item']}>
                                <div className={s['history__methods-item__header']}>
                                    <img src={PayPal} alt=""/>
                                </div>
                                <div className={s['history__methods-item__info']}>
                                    <span>Paypal</span>
                                    <span>versatile@paypal.com</span>
                                </div>
                            </div>
                            <button className={classNames(s['history__methods-item'], s['history__methods-item_more'])}>
                                <div>
                                    <img src={Plus} alt=""/>
                                </div>
                                <span>Add More Payment Method</span>
                            </button>
                        </div>
                    </div>*/}
                    <div className={s.history__block}>
                        <h3>Payment History</h3>
                        <p>Change how you pay your plan</p>
                        {isLoading ? <Loader/> : <div className={s.history__history}>
                            <table className={s['history__history-table']} cellPadding={0} cellSpacing={0}>
                                <thead>
                                <tr>
                                    <th>Payment Invoice</th>
                                    <th>Service</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.invoices.map((i: any) => (
                                    <tr key={i.invoice_id}>
                                        <td>Invoice #{i.invoice_id}</td>
                                        <td>{i.event.event_name}</td>
                                        <td>${i.amount}</td>
                                        <td>{dayjs(i.payment_created).format("D MMM, YYYY")}</td>
                                        <td>
                                            <div
                                                className={classNames(s['history__history-status'],
                                                    i.payment_status == "complete" && s['history__history-status_success'],
                                                    i.payment_status == "failed" && s['history__history-status_failed'],
                                                    i.payment_status == "pending" && s['history__history-status_pending'])}>
                                                <div></div>
                                                <span>{i.payment_status}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href={i.invoice_url} download={true} className={s['history__history-download']}>
                                                <img src={Download} alt=""/>
                                                <span>Download</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default History;