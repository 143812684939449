import React from 'react';
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Calendar from "../../components/Calendar/Calendar";
import Layout from "../../components/Layout/Layout";

const CustomerCalendar = () => {
    return (
        <Layout>
            <Hero isBackground={true} isSmall={true}/>
            <Calendar/>
        </Layout>
    );
};

export default CustomerCalendar;