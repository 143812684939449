import React from 'react';
import Hero from "../../components/Hero/Hero";
import CalendarComponent from "../../components/Calendar/Calendar";
import Layout from "../../components/Layout/Layout";

const Calendar = () => {
    return (
        <Layout>
            <Hero isBackground={true} height={550}/>
            <CalendarComponent/>
            {/*<Hero image={Image2}>
                <h1>Start your Day <span>Feeling</span>. <br/>
                    Calm and in Control</h1>
                <p>Simplify your workflow by focus on the right things and
                    organize your time based on the most important things
                    in your life.</p>
                <Button text={"Contact Sales"} isBig={true}/>
            </Hero>
            <Companies/>
            <ImageSection title={'A simple way to manage multiple services'} text={'With one click, view the schedule of every employee. Select\n' +
                'any or all employees to see their schedules side by side. If\n' +
                'you are using our mobile app, view your calendar in landscape\n' +
                'mode to easily manage multiple employees simultaneously.'} image={ImageSection1}/>
            <ImageSection title={'A simple way to manage multiple services'} text={'With one click, view the schedule of every employee. Select\n' +
                'any or all employees to see their schedules side by side. If\n' +
                'you are using our mobile app, view your calendar in landscape\n' +
                'mode to easily manage multiple employees simultaneously.'} image={ImageSection2} isAlt={true}/>
            <Contacts/>*/}
        </Layout>
    );
};

export default Calendar;