import React from 'react';
import s from './Summary.module.scss';
import MasterCard from './../../assets/images/payment-history/mastercard.svg';
import Button from "../UI/Button/Button";
import classNames from "classnames";

interface ISummary {
    data: any
    url: any
    payCash: () => any
}
const Summary = ({data, url, payCash}: ISummary) => {
    return (
        <section className={s.summary}>
            <div className="container">
                <h1>Summary</h1>
                <p>This shows all the options you have selected.</p>
                <div className={s.services}>
                    <div className={s.services__header}>
                        <p>Your plan (Billed monthly)</p>
                        {/*<a href="#">Change plan</a>*/}
                    </div>
                    <h3>Services</h3>
                    <div className={s.services__items}>
                        {data && data.line_items.map((i: any) => (
                            <div className={s['services__items-item']} key={i.subtext}>
                                <div>
                                    <span>{i.product_name}</span>
                                    <span>{i.subtext}</span>
                                </div>
                                <span>{i.dollars}</span>
                            </div>
                        ))}
                    </div>
                    <div className={classNames(s.services__items, s.services__items_total)}>
                        <div className={s['services__items-item']}>
                            <div>
                                <span>Total Price</span>
                            </div>
                            <span>{data && data.total_price}</span>
                        </div>
                    </div>
                </div>
                {/*<div className={s.summary__row}>
                    <div className={s.services}>
                        <div className={s.services__header}>
                            <p>Mastercard</p>
                            <a href="#">View all</a>
                        </div>
                        <div className={s.services__card}>
                            <img src={MasterCard} alt=""/>
                            <span>···· ···· ···· 7769</span>
                        </div>
                        <div className={s.services__info}>
                            <div className={s['services__info-item']}>
                                <span>Mastercard</span>
                                <span>Expires 06/24</span>
                            </div>
                            <div className={s['services__info-item']}>
                                <span>Billed on the first of every month</span>
                            </div>
                            <div className={s['services__info-item']}>
                                <span>You will be billed on the <strong>24/09</strong> and will be charged <strong>$0.00</strong></span>
                            </div>
                        </div>
                    </div>
                    <div className={s.services}>
                        <div className={s.services__header}>
                            <p>Invoices</p>
                            <a href="#">View all</a>
                        </div>
                        <div className={s.services__invoices}>
                            <div className={s['services__invoices-item']}>
                                <span className={s['services__invoices-item__info']}><strong>250$</strong> was spent on 24th December 2022</span>
                                <span className={s['services__invoices-item__status']}>Completed</span>
                            </div>
                            <div className={s['services__invoices-item']}>
                                <span className={s['services__invoices-item__info']}><strong>250$</strong> was spent on 24th December 2022</span>
                                <span className={s['services__invoices-item__status']}>Completed</span>
                            </div>
                            <div className={s['services__invoices-item']}>
                                <span className={s['services__invoices-item__info']}><strong>250$</strong> was spent on 24th December 2022</span>
                                <span className={s['services__invoices-item__status']}>Completed</span>
                            </div>
                            <div className={s['services__invoices-item']}>
                                <span className={s['services__invoices-item__info']}><strong>250$</strong> was spent on 24th December 2022</span>
                                <span className={s['services__invoices-item__status']}>Completed</span>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div className={s.summary__buttons}>
                    <a href={url}><Button text={'Purchase now'}/></a>
                    <Button text={'Pay with Cash'} className={s.summary__button} onClick={payCash}/>
                </div>
            </div>
        </section>
    );
};

export default Summary;