import React from 'react';
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import VersatilePay from "../../components/VersatilePay/VersatilePay";
import Footer from "../../components/Footer/Footer";
import History from "../../components/History/History";
import Layout from "../../components/Layout/Layout";

const PaymentHistory = () => {
    return (
        <Layout>
            <Hero isBackground={true}/>
            <History/>
        </Layout>
    );
};

export default PaymentHistory;