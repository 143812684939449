import React from 'react';
import s from './Team.module.scss';
import Photo1 from './../../assets/images/team/photo1.png';
import Discord from './../../assets/images/team/discord.svg';
import Figure from './../../assets/images/team/figure.svg';

const Team = () => {
    return (
        <section className={s.team}>
            <div className="container">
                <h2>Our <span>Team</span></h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod <br/>
                    tempor incididunt ut labore et dolore magna aliqua.</p>
                <div className={s.team__items}>
                    <div className={s['team__items-item']}>
                        <div className={s['team__items-item__photo']}>
                            <div><img src={Photo1} alt="Nic"/></div>
                        </div>
                        <h3>Nic</h3>
                        <span>Director of Operations</span>
                        <p>Manages day to day tasks needed to
                            maintain our services and business</p>
                        <a href="#" className={s['team__items-item__discord']}>
                            <img src={Discord} alt="Discord"/>
                            <img src={Figure} alt="Figure"/>
                        </a>
                    </div>
                    <div className={s['team__items-item']}>
                        <div className={s['team__items-item__photo']}>
                            <div><img src={Photo1} alt="Nic"/></div>
                        </div>
                        <h3>Nic</h3>
                        <span>Director of Operations</span>
                        <p>Manages day to day tasks needed to
                            maintain our services and business</p>
                        <a href="#" className={s['team__items-item__discord']}>
                            <img src={Discord} alt="Discord"/>
                            <img src={Figure} alt="Figure"/>
                        </a>
                    </div>
                    <div className={s['team__items-item']}>
                        <div className={s['team__items-item__photo']}>
                            <div><img src={Photo1} alt="Nic"/></div>
                        </div>
                        <h3>Nic</h3>
                        <span>Director of Operations</span>
                        <p>Manages day to day tasks needed to
                            maintain our services and business</p>
                        <a href="#" className={s['team__items-item__discord']}>
                            <img src={Discord} alt="Discord"/>
                            <img src={Figure} alt="Figure"/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;