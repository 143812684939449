import React, {useEffect, useState} from 'react';
import s from "../Dashboard.module.scss";
import dayjs from "dayjs";

const Timer = () => {
    let [time, setTime] = useState(dayjs());

    useEffect(() => {
        setTimeout(() => {
            setTime(dayjs());
        }, 1000);
    }, [time]);

    return (
        <div className={s.dashboard__timer}>
            <span>{time.format('MMMM DD, YYYY')}</span>
            <strong>{time.format('h:mm A')}</strong>
        </div>
    );
};

export default Timer;