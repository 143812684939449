import React, {useEffect} from 'react';
import s from "../../Auth.module.scss";
import {Formik, FormikValues} from "formik";
import {auth, googleLogin, login} from "../../../../store/actionCreators";
import Google from "../../../UI/Google/Google";
import Input from "../../../UI/Input/Input";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import {NavLink} from "react-router-dom";
import Button from "../../../UI/Button/Button";
import * as Yup from "yup";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {GoogleLogin} from "@react-oauth/google";

const Login = () => {
    let dispatch = useAppDispatch();
    let authData = useAppSelector((state) => state.auth);
    
    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    let handleSubmit = (values: FormikValues) => {
        dispatch(login({email: values.email, password: values.password})).then(() => {
            if (localStorage.getItem('token')) {
                dispatch(auth());
            }
        });
    }

    return (
        <div className={s['auth__block-container']}>
            <div className={s['auth__block-info']}>
                <h2>Welcome Back</h2>
                <p>Continue with Google or enter your details</p>
            </div>
            <Formik initialValues={{email: '', password: '', remember: false }}
                    validationSchema={loginSchema}
                    onSubmit={handleSubmit}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form className={s['auth__block-form']} onSubmit={handleSubmit}>
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log(credentialResponse);
                                // @ts-ignore
                                dispatch(googleLogin({credential: credentialResponse.credential})).then(() => {
                                    if (localStorage.getItem('token')) {
                                        dispatch(auth());
                                    }
                                });
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                        <Input placeholder={'Enter your email'} label={'Email'} value={values.email} onChange={handleChange} name={'email'} error={touched.email && errors.email}/>
                        <Input placeholder={'Enter your password'} label={'Password'} value={values.password} onChange={handleChange} name={'password'} type={'password'} error={touched.password && errors.password}/>
                        <div className={s['auth__block-form__controls']}>
                            <Checkbox text={'Remember for 30 days'} checked={values.remember} name={'remember'}/>
                            <NavLink to={'/forgot-password'}>Forgot Password?</NavLink>
                        </div>
                        <Button type={'submit'} text={'Login'} isBig={true} disabled={authData.isLoading}/>
                        {authData.error && <span className={s.error}>{authData.error}</span>}
                    </form>
                )}
            </Formik>
            <div className={s['auth__block-link']}>
                <span>Don’t have an account? <NavLink to={'/signup'}>Signup for free</NavLink></span>
            </div>
        </div>
    );
};

export default Login;