import React from 'react';
import s from './About.module.scss';
import Button from "../UI/Button/Button";
import Tags from "../../assets/images/about/tags.png";
import classNames from "classnames";

interface IAbout {
    isAlt?: boolean
}
const About = ({isAlt}: IAbout) => {
    return (
        <section className={classNames(s.about, isAlt && s.about_alt)}>
            <div className="container">
                <div className={s.about__we}>
                    <h1>{isAlt ? 'Who are we?' : 'Who we are?'}</h1>
                    {isAlt ? <Button text={'Read more'} isWhite={true} isBig={true} rounded={true}/> : <Button text={'Read more'} isWhite={true} isBig={true}/>}
                </div>
                <div className={s.about__info}>
                    <h2>About Us</h2>
                    {isAlt ? <>
                        <p>Welcome to our software development studio! We are a team of experienced and innovative  software engineers who are committed to creating cutting-edge solutions that solve complex  problems for businesses and organizations. Our mission is to deliver high-quality software that  is tailored to our clients' unique needs and helps them achieve their goals.
                        </p>
                    </> : <>
                        <p>We are a Software Development Company with several services.</p>
                        <p>We put our confidence in offering high-quality services to all our customers and clients.</p>
                    </>}
                    {isAlt ? <div className={s.about__tags}>
                        <div className={classNames(s['about__tags-item'], s['about__tags-item_blue'])}>
                            Game Development
                        </div>
                        <div className={classNames(s['about__tags-item'])}>
                            Web Application
                        </div>
                        <div className={classNames(s['about__tags-item'])}>
                            Software Services
                        </div>
                        <div className={classNames(s['about__tags-item'], s['about__tags-item_pink'])}>
                            WordPress Development
                        </div>
                        <div className={classNames(s['about__tags-item'], s['about__tags-item_pink'])}>
                            Web Scraping
                        </div>
                        <div className={classNames(s['about__tags-item'], s['about__tags-item_blue'])}>
                            Mobile Application
                        </div>
                    </div> : <img src={Tags} alt="Tags"/>}
                </div>
            </div>
        </section>
    );
};

export default About;