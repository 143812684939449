import React, {ReactElement, ReactNode, useEffect} from 'react';
import s from './AuthComponent.module.scss';
import {useAppSelector} from "../../store/hooks";
import {Navigate, useNavigate} from "react-router-dom";

interface IAuthComponent {
    children: any
}
const AuthComponent = ({children}: IAuthComponent) => {
    const authData = useAppSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!authData.data.isAuth) {
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        if (!authData.data.isAuth) {
            navigate("/login");
        }
    }, [authData.data.isAuth]);

    return children;
};

export default AuthComponent;