import React from 'react';
import classNames from "classnames";
import s from "../../Auth.module.scss";
import {Formik} from "formik";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {authAPI, backendAPI} from "../../../../api/backendAPI";
import toast from "react-hot-toast";

const ForgotPassword = () => {
    let dispatch = useAppDispatch();
    let authData = useAppSelector((state) => state.auth);

    return (
        <div className={classNames(s['auth__block-container'], s['auth__block-container_start'])}>
            <div className={s['auth__block-info']}>
                <h2>Forgot Password</h2>
                <p>Enter the email address associated with your account
                    and we’ll send you a link to reset your password.</p>
            </div>
            <Formik initialValues={{email: '' }} onSubmit={async (values, { setSubmitting }) => {
                try {
                    let response = await authAPI.forgotPassword(values.email);
                    toast.success(response.detail);
                } catch (e) {
                    toast.error("Error");
                }
            }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form className={s['auth__block-form']} onSubmit={handleSubmit}>
                        <Input placeholder={'Enter your email'} label={'Email'} value={values.email} onChange={handleChange} name={'email'}/>
                        <Button text={'Send Forgot Password Link'} type={'submit'} isBig={true} disabled={isSubmitting}/>
                        {authData.error && <span className={s.error}>{authData.error}</span>}
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPassword;