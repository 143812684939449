import React from 'react';
import s from './Unavailable.module.scss';
import CorruptedFile from './../../assets/images/icons/corrupted-file.svg';
import classNames from "classnames";

interface IUnavailable {
    title: string
    text: string
    isFull?: boolean
    isGreyTitle?: boolean
}
const Unavailable = ({title, text, isFull, isGreyTitle}: IUnavailable) => {
    return (
        <div className={classNames(s.unavailable, isFull && s.unavailable_full, isGreyTitle && s.unavailable_grey)}>
            <img src={CorruptedFile} alt=""/>
            <strong>{title}</strong>
            <p>{text}</p>
        </div>
    );
};

export default Unavailable;