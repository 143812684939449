import React from 'react';
import s from './Checkbox.module.scss';
import {Field} from "formik";

interface ICheckbox {
    text: string
    checked?: boolean
    name?: string
}
const Checkbox = ({text, checked, name}: ICheckbox) => {
    return (
        <label className={s.checkbox}>
            <Field type="checkbox" name={name}/>
            <span className={s.checkbox__checkmark}></span>
            <span>{text}</span>
        </label>
    );
};

export default Checkbox;