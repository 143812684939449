import React from 'react';
import s from './Layout.module.scss';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

interface ILayout {
    children: any
    isAltHeader?: boolean
}
const Layout = ({children, isAltHeader}: ILayout) => {
    return (
        <div className={s.layout}>
            <Header isAlt={isAltHeader}/>
            <main className={s.layout__content}>
                {children}
            </main>
            <Footer/>
        </div>
    );
};

export default Layout;