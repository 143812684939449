import React, {useState} from 'react';
import s from './TrainerSelect.module.scss';
import {ReactComponent as Arrow} from "./../../assets/images/icons/arrow.svg";
import Avatar from "./../../assets/images/settings/photo.svg";
import classNames from "classnames";
import Button from "../UI/Button/Button";

interface ITrainerSelect {
    isSmall?: boolean
    isSingle?: boolean
    trainers?: any
    setTrainers?: any
}
const TrainerSelect = ({isSmall, isSingle, trainers, setTrainers}: ITrainerSelect) => {
    let [isActive, setIsActive] = useState(false);

    let selectedTrainers = trainers.filter((t: any) => t.selected == true);

    let toggleTrainer = (trainerId: number) => {
        if (!isSingle) {
            setTrainers(trainers.map((t: any) => {
                if (t.trainer_id === trainerId) {
                    return {...t, selected: !t.selected};
                }
                return t;
            }));
        } else {
            setTrainers(trainers.map((t: any) => {
                if (t.trainer_id === trainerId) {
                    return {...t, selected: true};
                }
                return {...t, selected: false};
            }));
        }
    };

    return (
        <div className={classNames(s['select-wrapper'], isSmall && s['select-wrapper_small'])}>
            <span>Trainer to train service</span>
            <div className={classNames(s.select, isSmall && s.select_small, trainers.length == 0 && s['select_disabled'])}>
                <div className={s.select__header} onClick={() => {trainers.length != 0 && setIsActive(!isActive)}}>
                    <span>{selectedTrainers.length ? selectedTrainers.map((t: any) => t.first_name + " " + t.last_name).join(", ") : (trainers.length == 0 ? "No trainer available" : "Trainer to train service")}</span>
                    <Arrow/>
                </div>
                {isActive && <div className={s.select__body}>
                    {/*<div className={s.search}>
                        <input type="text" placeholder={'Search Services'} value={search} onChange={(e) => {setSearch(e.currentTarget.value)}}/>
                        <Arrow/>
                    </div>*/}
                    <div className={s.trainers}>
                        {trainers && trainers.map((t: any) => (
                            <div className={classNames(s.trainers__item, t.selected && s.trainers__item_active)} key={t.trainer_id}>
                                <div className={s['trainers__item-info']}>
                                    <img src={Avatar} alt=""/>
                                    <div>
                                        <strong>{t.first_name} {t.last_name}</strong>
                                        <span>Gym trainer</span>
                                    </div>
                                </div>
                                <Button text={t.selected ? "Trainer selected" : "Select trainer"} onClick={() => {toggleTrainer(t.trainer_id)}} isSmall={true}/>
                            </div>
                        ))}
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default TrainerSelect;