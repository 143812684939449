import React from 'react';
import s from './Footer.module.scss';
import Logo from './../../assets/images/logo.png';
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <footer className={s.footer}>
            <div className={"container"}>
                <NavLink to={"/dashboard"} className={s.footer__logo}>
                    <img src={Logo} alt="Logo"/>
                </NavLink>
                <div className={s.footer__menu}>
                    <span>Useful Links</span>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Support</a></li>
                        <li><a href="#">FAQ</a></li>
                    </ul>
                </div>
                <div className={s.footer__menu}>
                    <span>Social Media</span>
                    <ul>
                        <li><a href="#">Facebook</a></li>
                        <li><a href="#">Instagram</a></li>
                        <li><a href="#">Google</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;