import React from 'react';
import s from './Contacts.module.scss';
import Phone from './../../assets/images/icons/phone.svg';
import Mail from './../../assets/images/icons/mail.svg';
import Location from './../../assets/images/icons/location.svg';
import User from './../../assets/images/icons/user.svg';
import Message from './../../assets/images/icons/message.svg';
import Building from './../../assets/images/icons/building.svg';
import Gear from './../../assets/images/icons/gear.svg';
import Hours from './../../assets/images/icons/24-hours.svg';
import Arrow from './../../assets/images/icons/arrow.svg';
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import classNames from "classnames";

interface IContacts {
    isAlt?: boolean
}
const Contacts = ({isAlt}: IContacts) => {
    return (
        <>
            <section className={classNames(s.contacts, isAlt && s.contacts_alt)}>
                <div className={"container"}>
                    <h2>Let's <span>Talk?</span></h2>
                    <p>We will respond to your message as soon as possible.</p>
                    <div className={s.contacts__block}>
                        <div className={s.contacts__info}>
                            <h2>Information</h2>
                            <div className={s['contacts__info-items']}>
                                <a href="#" className={s['contacts__info-items__item']}>
                                    <div>
                                        <img src={Phone} alt=""/>
                                    </div>
                                    <span>+61 481 364 014</span>
                                </a>
                                <a href="#" className={s['contacts__info-items__item']}>
                                    <div>
                                        <img src={Mail} alt=""/>
                                    </div>
                                    <span>contact@versatile.software</span>
                                </a>
                                <a href="#" className={s['contacts__info-items__item']}>
                                    <div>
                                        <img src={Location} alt=""/>
                                    </div>
                                    <span>197 Mann St, Gosford, New South
                                Wales, Australia 2250</span>
                                </a>
                            </div>
                            <img src={Arrow} alt="" className={s['contacts__info-arrow']}/>
                        </div>
                        <div className={s.contacts__form}>
                            <form action="">
                                <Input icon={User} placeholder={'Enter you name'}/>
                                <div className={s['contacts__form-row']}>
                                    <Input icon={Mail} placeholder={'Enter email address'}/>
                                    <Input icon={Phone} placeholder={'Phone number'}/>
                                </div>
                                <Input icon={Message} placeholder={'Write message here...'}/>
                                <Button text={'Send Message'} isBig={true}/>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className={s.advantages}>
                <div className={"container"}>
                    <div className={s.advantages__items}>
                        <div className={s['advantages__items-item']}>
                            <div className={s['advantages__items-item__header']}>
                                <div>
                                    <img src={Building} alt=""/>
                                </div>
                                <h3>Company</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod...</p>
                        </div>
                        <div className={s['advantages__items-item']}>
                            <div className={s['advantages__items-item__header']}>
                                <div>
                                    <img src={Gear} alt=""/>
                                </div>
                                <h3>Services</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod...</p>
                        </div>
                        <div className={s['advantages__items-item']}>
                            <div className={s['advantages__items-item__header']}>
                                <div>
                                    <img src={Hours} alt=""/>
                                </div>
                                <h3>24h Supports</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod...</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contacts;